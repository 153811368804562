import { useAuth0 } from '@auth0/auth0-react'
import React from 'react'
import { Link, Logo, NavContainer, NavItems, NavLink } from '@upvestcz/shared-components'
import { useLogout } from '../auth'

function Navbar() {
    const { isAuthenticated } = useAuth0()
    const logout = useLogout()

    return (
        <NavContainer>
            <Link aria-label="Upvest Logo" to="/">
                <Logo />
            </Link>
            <NavItems ml="auto">
                {isAuthenticated && (
                    <NavLink
                        as="button"
                        onClick={() =>
                            logout({ returnTo: `${window.location.origin}?isLogout=true` })
                        }
                    >
                        Odhlásit se
                    </NavLink>
                )}
            </NavItems>
        </NavContainer>
    )
}

export default Navbar
