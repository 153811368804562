import { destroyCookie } from '@upvestcz/common/cookies'
import { useAuth0 } from '@auth0/auth0-react'
import { destroyLoginCookies } from '@upvestcz/common/auth'

export const clearAuthCookies = () => {
    destroyLoginCookies()
    destroyCookie(null, 'auth0.is.authenticated')
}

export const useLogout = () => {
    const { logout: auth0Logout } = useAuth0()

    return ({ returnTo }) => {
        clearAuthCookies()

        auth0Logout({ returnTo })
    }
}
