import * as Sentry from '@sentry/nextjs'

const logger = {
    error(err: any) {
        Sentry.captureException(err)

        // eslint-disable-next-line no-console
        console.error(err)
    },
}

export default logger
