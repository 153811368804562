import { useMemo } from 'react'
import Head from 'next/head'
import { ChakraProvider, ScrollInfoProvider, theme } from '@upvestcz/shared-components'
import { UniqueIdProvider } from '@upvestcz/common/hooks'
import { Auth0Provider, withAuthenticationRequired } from '@auth0/auth0-react'
import { SWRConfig } from 'swr'
import { ApolloProvider } from '@apollo/client'
import { AppContext, AppProps } from 'next/app'
import { useApolloClient } from '@upvestcz/common/graphql/client'
import FontsHead from '@upvestcz/shared-components/head/fonts'
import FaviconsHead from '@upvestcz/shared-components/head/favicons'
import { DEFAULT_NS } from '@upvestcz/common/i18n'
import { Locale, LOCALES } from '@upvestcz/common/i18n/locales'
import logger from '../logger'
import { tryToLoadTranslations } from '../i18n'
import { getAppLayout } from '../components/layouts/AppLayout'

// function intentionality left empty as we want to take care
// of redirecting ourselves
// eslint-disable-next-line @typescript-eslint/no-empty-function
const onRedirectCallback = () => {}

// We create an extra component for tracking as we need access
// to the Auth0 provider data
function Tracking() {
    // no tracking right now.

    return null
}

function MyApp({
    Component,
    pageProps,
    MAINTENANCE_MESSAGE,
}: AppProps & { Component: TODO; pageProps: TODO; MAINTENANCE_MESSAGE?: string }) {
    const client = useApolloClient(pageProps)

    const AuthenticatedComponent = useMemo(() => {
        return Component.shouldDisableAuth
            ? Component
            : withAuthenticationRequired(Component, {
                  loginOptions: { connection: process.env.NEXT_PUBLIC_KB_SAML_CONNECTION },
              })
    }, [Component])

    // If the page component has a `getLayout` "static" property on it
    // we use it the get the layout for the component.
    // If it does not, we use a default layout ("AppLayout") for it.
    // You can read more about the layout "pattern" here:
    // https://adamwathan.me/2019/10/17/persistent-layout-patterns-in-nextjs/
    const getLayout = Component.getLayout || getAppLayout

    return (
        <ApolloProvider client={client}>
            <ChakraProvider theme={Component.chakraTheme ?? theme} resetCSS>
                <UniqueIdProvider>
                    <ScrollInfoProvider>
                        <Auth0Provider
                            domain={process.env.AUTH0_KB_DOMAIN!}
                            clientId={process.env.AUTH0_KB_CLIENT_ID!}
                            redirectUri={
                                typeof window !== 'undefined'
                                    ? `${window.location.origin}/login-callback`
                                    : undefined
                            }
                            audience={`https://${process.env.AUTH0_KB_DOMAIN!}/api/v2/`}
                            scope="openid profile email"
                            onRedirectCallback={onRedirectCallback}
                            cacheLocation="localstorage"
                        >
                            <SWRConfig
                                value={{
                                    onError: err => {
                                        logger.error(err)
                                    },
                                    // The default `dedupingInterval` was too high and caused
                                    // stale data to be returned when navigating across the application
                                    // at high pace
                                    dedupingInterval: 100,
                                }}
                            >
                                <Head>
                                    <title>Upvest – KB distribuce</title>
                                    {FontsHead()}
                                    {FaviconsHead()}
                                </Head>
                                <Tracking />
                                {getLayout(<AuthenticatedComponent {...pageProps} />, {
                                    MAINTENANCE_MESSAGE,
                                })}
                            </SWRConfig>
                        </Auth0Provider>
                    </ScrollInfoProvider>
                </UniqueIdProvider>
            </ChakraProvider>
        </ApolloProvider>
    )
}

MyApp.getInitialProps = async ({ ctx }: AppContext) => {
    const { translations } = await tryToLoadTranslations({
        relativeFilePath: DEFAULT_NS,
        // WARNING: use locale from ctx, not router! router.locale does not behave correctly on CSR with middleware.
        locale: (ctx.locale || ctx.defaultLocale || LOCALES.CS) as Locale,
    })

    // fetch this at runtime in getInitialProps
    // eslint-disable-next-line prefer-destructuring
    const MAINTENANCE_MESSAGE = process.env.MAINTENANCE_MESSAGE

    return {
        props: {
            translations,
            MAINTENANCE_MESSAGE,
        },
    }
}

export default MyApp
