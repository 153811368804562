import { ReactNode } from 'react'
import { AnnouncementBar, Box, Container, ContentContainer } from '@upvestcz/shared-components'
import Footer from '../Footer'
import Navbar from '../Navbar'

type LayoutProps = {
    MAINTENANCE_MESSAGE?: string
    children: ReactNode
}
const AppLayout = ({ children, MAINTENANCE_MESSAGE }: LayoutProps) => {
    return (
        <>
            <Navbar />
            {MAINTENANCE_MESSAGE && (
                <AnnouncementBar bg="highlightWarning">{MAINTENANCE_MESSAGE}</AnnouncementBar>
            )}
            <Container display="flex" flexGrow={1} flexDirection="column">
                <Box flexGrow={1}>
                    <ContentContainer>{children}</ContentContainer>
                </Box>
                <Footer />
            </Container>
        </>
    )
}

export const getAppLayout = (page: ReactNode, layoutProps: LayoutProps) => (
    <AppLayout {...layoutProps}>{page}</AppLayout>
)

export default AppLayout
