import { Box, Text } from '@upvestcz/shared-components'

function Footer() {
    return (
        <Box my={8} display="flex" justifyContent="center">
            <Text>Podpora na tel. +420 773 633 925</Text>
        </Box>
    )
}

export default Footer
